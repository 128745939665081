<template>
  <app-module-view>
    <template slot="buttons">
      <app-button-create
        route-name="videoCategory_new"
      >
      </app-button-create>
    </template>
    <template slot="body">
      <app-video-category-filter></app-video-category-filter>
      <div class="card">
        <div class="card-body">
          <app-preloader v-if="callingAPI"></app-preloader>
          <app-data-table
            :data="videoCategories"
            :totalCount="totalCount"
            :page="page"
            :config="dataTableConfig"
            @page-change="setPageAndGetRecords"
          >
          </app-data-table>
        </div>
      </div>
    </template>
  </app-module-view>
</template>

<script>
import ModuleView from '../../components/ModuleView'
import { mapState } from 'vuex'
import Preloader from '../../components/preloader/Preloader'
import VideoCategoryFilter from '../../components/video/VideoCategoryFilter'
import DataTable from '../../components/shared/DataTable'
import ButtonCreate from '../../components/shared/ButtonCreate'

export default {
  name: 'VideoCategoryListView',
  data () {
    return {
      dataLoaded: false,
      dataTableConfig: {
        fields: {
          id: this.$t('videoCategory.list.id'),
          title: this.$t('videoCategory.list.title'),
          slug: this.$t('videoCategory.slug'),
          enabled: this.$t('videoCategory.list.enabled'),
          videoCount: this.$t('videoCategory.list.video_count')
        },
        actions: {
          edit: 'videoCategory_edit'
        },
        render: {
          enabled: function (data) {
            if (data) {
              return '<i class="fas fa-check-circle"></i>'
            }
          }
        }
      }
    }
  },
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    videoCategories () {
      return this.$store.getters['videoCategory/list']
    },
    totalCount () {
      return this.$store.getters['videoCategory/totalCount']
    },
    page () {
      return this.$store.getters['videoCategory/page']
    }
  },
  components: {
    appModuleView: ModuleView,
    appPreloader: Preloader,
    appVideoCategoryFilter: VideoCategoryFilter,
    appDataTable: DataTable,
    appButtonCreate: ButtonCreate
  },
  methods: {
    getVideoCategories () {
      this.$store.dispatch('videoCategory/fetch')
        .then(() => {
          this.dataLoaded = true
        })
    },
    setPageAndGetRecords (page) {
      this.$store.commit('videoCategory/setPage', page)
      this.getVideoCategories()
    }
  },
  mounted () {
    this.getVideoCategories()
  }
}
</script>

<style lang="scss" scoped>

.table-responsive {
  th, td {
    font-size: 14px
  }
}

</style>
